import Image from "next/image";

export default function HomeCallToAction() {
    return (
        <>
            <section className="ds-call-to-action ptb-120 position-relative overflow-hidden z-1" >
            <span className="gradient-overlay position-absolute start-0 top-0 w-100 h-100 z-1"></span>
                <Image
                    src={'/img/dealers/automobile-7702271_1280.jpg'}
                    alt="not found"
                    fill
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'right center',
                        zIndex: -1
                    }} />
                <Image
                    src="/img/shapes/orangebackground.png"
                    alt="not found"
                    width={470}
                    height={598}
                    sizes="100vw"
                    quality={100}
                    style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                        zIndex: 1
                    }} />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10">
                            <div className="section-content text-center position-relative z-1">
                                <h2 className="h1 text-white mb-3">Fazemos Os Melhores Negócios</h2>
                                <p className="mb-5 text-white">Buscamos sempre a excelência em nossas negociações tanto na compra como na venda de veículos. A satisfação do cliente é nosso maior objetivo.</p>
                                <div className="cta-btns-wrapper d-flex align-items-center justify-content-center flex-wrap">
                                    <a href="/estoque" className="btn header-white-btn">Ver Estoque Completo</a>
                                    <a href="/venda-seu-veiculo" className="btn header-white-btn">Venda Seu Veículo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}